<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">变造票据，这样能遮掩过去吗？</div>
            <p class="time">2020-01-16</p>
            
            <p class="space_content">
                随着社会经济不断发展，涉及合同、协议、遗嘱等文书类司法鉴定的案件越来越多，通过改变关键字句、关键数据、关键页以谋求非法利益是最常见的变造手法。
            </p>
            <p class="titlee2" >案件详情</p>
            <p class="space_content">
                某建筑公司与某物料公司结算用料款时，发现结算的用料数量异常，以涉嫌诈骗向公安机关报案。警方调取了物料公司留存的相关凭证，其中一张日期为“2018年6月6日”的《领料单》存在较大争议。
            </p>
            <p class="space_content">
                此领料单上的登记的钢环材料数量为5500个，单价10元，总值为55000，钢扣材料数量为2500个，单价10元，总值为25000，并且还有某建筑公司领料人丁某的签名以及某物料公司发料人王某的签名。
            </p>
            <p class="space_content">
                某建筑公司领料人丁某称，2018年6月确实有领过钢环和钢扣，虽此领料单的复写联已丢失，但自己清楚记得所领取的钢环材料数量为550个，钢扣材料数量为250个。丁某表明领料单上的签名确实本人所签，但所填写的材料数量和总值并不对，怀疑领料单上的数据造假。
            </p>
            <p class="space_content">
                而某物料公司发料人王某却称，领料单上所填写的材料数量是根据实际发料的情况如实填写的，并未造假。
            </p>
            <p class="space_content">
                警方为查清案件事实，委托福建历思司法鉴定所对《领料单》的数据进行文件司法鉴定。
            </p>
            <div class="col8" style="margin:0 auto">
                <img class="col8"  v-lazy="require('../../assets/case/2-1.png')">
            </div>
            <div class="col8" style="margin:0 auto">
                <img class="col8"  v-lazy="require('../../assets/case/2-2.png')">
            </div>
            <div class="col8" style="margin:0 auto">
                <img class="col8"  v-lazy="require('../../assets/case/2-3.png')">
            </div>
            <div class="col8" style="margin:0 auto">
                <img class="col8"  v-lazy="require('../../assets/case/2-4.png')">
            </div>



            <p class="space_content">
                福建历思司法鉴定所接受委托后，对此张领料单进行检验鉴定，发现领料单上钢环数量的“5500”中最后一个“0”字、总值的“55000”中最后一个“0”字，钢扣数量的“2500”中最后一个“0”字、总值的“25000”中最后一个“0”字与其它表格内手写内容不是同一支笔一次性书写形成。
            </p>


            <p class="space_content">
                警方根据福建历思司法鉴定所的鉴定结果，对某物料公司发料人王某进行再次询问，王某最终交代自己为谋取私利，私自篡改了领料单上的数据，承认了自己票据变造的行为。王某表示自己特意找难被发现篡改的单据，以为能遮掩过去，没想到还是难逃司法鉴定的法眼。
            </p>
            
            <p class="space_content">
                随着票据在经济生活中的广泛应用，通过变造票据等违法行为实施诈骗的案件时有发生，给票据的正常使用和流通带来极大危害，也严重扰乱了社会经济秩序。 
接下来，让我们来了解一下关于票据变造的相关问题：

            </p>
            
            <p class="titlee2" >什么是“票据变造”？</p>


            
            <p class="space_content">
                “票据变造”是指无权更改票据内容的人，对票据上签章以外的记载事项加以改变的行为，如变造票据的记载金额、数量、日期等。变造票据一般在合法票据的基础上，对票据加以剪接、挖补、覆盖、涂改，从而达到变更票据权利义务关系的目的。
            </p>
            <p class="titlee2" >“票据变造”认定的条件？</p>


            
            <p class="space_content" style="text-indent:0">
               （一）票据变造的前提，是该票据在变造前须为形式上有效的票据，对欠缺票据形式要件的票据进行增、删、变的，不构成票据变造；<br/><br/>（二）票据变造必须以改变票据权利义务为内容。如果行为人进行变造的内容只是与票据权利义务无关的记载事项或是变造后不影响票据权利义务内容的，不能视为票据变造；<br/><br/>（三）票据变造的内容系除签章以外的记载事项；<br/><br/>（四）票据变造的内容不属于法律禁止变更的记载事项，即变造后的票据仍须为形式上有效的票据。票据变造增记绝对记载事项导致票据无效的，则构成票据的毁损而不产生变造的结果；<br/><br/>
（五）票据变造须是无票据变更权人以行使票据权利为目的的票据行为。有变更权人的变更行为发生票据变更的效力，与票据变造不同。变造票据后不再行使票据权利的，因不发生对票据关系人义务增加的后果，也不会因流通而致票据交易受阻，故也不构成票据变造。

            </p>
            
            <p class="titlee2">
                “票据变造”的常见方法？
            </p>
            
            <p class="space_content" style="text-indent:0">
                1、加零法：就如以上的案例一样，在票据上所记载的金额、数量后直接加零，已达到篡改数据的目的。<br/><br/>2、改写法：在票据原字迹的基础上，通过添加笔画的方式，将原有文字、数字等进行改写。如3万改成8万，1万改成7万。<br/><br/>3、消退法：利用化学试剂或光、热等物理手段，使票据的文字色料发生化学或物理作用以去除部分文字内容。<br/><br/>4、擦刮法 ：用橡皮、小刀等工具擦除票据上某些内容，并在被刮擦的地方填写自己所需的内容。

            </p>
            
            <p class="space_content">
               如今，随着科学技术的不断发展，票据变造水平也在不断的提高，经变造的票据一般人很难识别出来，当你质疑对方票据的真实性时，可以到具有相关资质的鉴定机构进行司法鉴定。
            </p>
            
            
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    <router-link class="a" :to="'/case/trace_case/2'"><p class="ellipsis1">身份信息被盗用，如何证明“我不是真的我”...</p></router-link>

                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/case/trace_case'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>
<style scoped>
.space_content{
    margin-bottom:2rem;
    
}
</style>